<template>
  <div class="hello">
      <div id='container'>
        <div id='DmanTip' >
          <div id='date' class="position-item" >
            Fecha: 26.03.2021 09:58:31 UTC
          </div>
          <div id='tip' class="position-item" >
            APYS - I'd fucking top up this now. It's technically bullish and it's still a gem which I don't plan on selling until Q4 this year, because the founder is very fast, developing very fast. You see, you're an idiot if you think "no updates" that you don't get your premiums' worth of money. It was retracement times. The LESS you did, the more you did actually. You survived with least damages, and now we are about to go up. I say this to you, you don't need 10 coins, you're topping up on APYS let me tell you reasons, usually I just say for you to top up: 1) I know in 2 weeks or so they have one big news 2) I believe we are at or near the bottom for alts and they will start pumping now like crazy. So, this topup that you'll buy now for APYS you'll sell at that news in about two weeks. I expect you potentially making 50%-100% profit on this topup and still hold the gem allocation until later stages in this year. APYS has a strong, consistent and solid growth, very similar to omi, just without that one concern.
          </div>
      </div>
        <div id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha:  14.03.2021 10:06:42 UTC
          </div>
          <div id='tip'>
            Last call to top up on omi. I'll give it to free channel (maybe) but this is one of my two favorite gems that you can enter now. OMI and apys. OMI as I see clear gains and clear target reached, and apys because it's so versatile, the leader is so good, it will be very good in the months to come as they adjust fast.
          </div>
      </div>
        <div id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 10.03.2021 15:28:24 UTC
          </div>
          <div id='tip'>
            WHALE staking package at apys is capped out so you have hodl and that other package available if you haven't staked already.
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'APYS',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
